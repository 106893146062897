<template>
    <div class="con-wrap">
          
        <CarrotTitle title="명절 선물 관리">
            - 명절 선물 페이지 접수인원을 확인할 수 있습니다.
        </CarrotTitle>

        <div class="board">
            <div class="mt-30 float-left"
            >조회 일자 : 
                        <CarrotDatePicker  
                        v-model.sync="holiday.sdate"
                        class="w-110px "></CarrotDatePicker>
                        <span class="ml-10 mr-10">~</span>
                        <CarrotDatePicker  
                        v-model.sync="holiday.edate"
                        class="w-110px "></CarrotDatePicker>
                        <button 
                        @click="holiday.getHolidayData()"
                        class="btn-default custom-btn ml-15">검색</button>
            </div>
            <div class="dp-flex list">
                <button 
                @click="holiday.deleteHolidayData()"
                class="btn-default mt-10 ml-5">주소 데이터 삭제</button>
                
            </div>            
            <div class="dp-flex list">
                <button
                @click="holiday.downExcel('tutor')" 
                class="btn-default mt-10 ml-5">엑셀 다운로드 (강사)</button>
                <button 
                @click="holiday.downExcel('client')" 
                class="btn-default mt-10 ml-5">엑셀 다운로드 (담당자)</button>
            </div>                        
            <div class="mt-20">
                <div class="mt-50 mb-20">
                    <table class="table-row table-serach">
                        <tbody>
                            <tr>
                                <th width="180">강사 신청 인원</th>
                                <td class="float-center">
                                    {{ holiday.holidayCount.tutor_count }} 명
                                </td>
                            </tr>
                            <tr>
                                <th width="180">담당자 신청 인원</th>
                                <td class="float-center">
                                    {{ holiday.holidayCount.client_count }} 명
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="mt-30">
                        <hr/>
                    </div>
                    <CarrotTitle title="배송지 입력 페이지 설정">
                        배송지 입력 페이지의 이미지와 오픈기간을 설정할 수 있습니다. 
                    </CarrotTitle>
                    <div class="dp-flex list">
                        <button 
                        @click="inputPageSet.goPage('tutor')"
                        class="btn-default mt-10 ml-5">강사 페이지 바로가기</button>
                        <button 
                        @click="inputPageSet.goPage('client')"
                        class="btn-default mt-10 ml-5">담당자 페이지 바로가기</button>
                        <button 
                        @click="inputPageSet.goPage('finish')"
                        class="btn-default mt-10 ml-5">완료 페이지 바로가기</button>
                    </div>                              
                    <table class="table-row table-serach mt-30">
                        <tbody>
                            <tr>
                                <th width="180"><span class="color-1">*</span>배송지 입력 기간</th>
                                <td class="float-center">
                                    <CarrotDatePicker 
                                    v-model.sync="inputPageSet.giftData.start_date"
                                    class="w-110px "></CarrotDatePicker>
                                    <span class="ml-10 mr-10">~</span>
                                    <CarrotDatePicker 
                                    v-model.sync="inputPageSet.giftData.end_date"
                                    class="w-110px "></CarrotDatePicker>
                                </td>
                            </tr>

                            <tr>
                                <th width="180"><span class="color-1">*</span>강사 페이지 (PC)</th>
                                <td class="float-center">
                                    <input 
                                    @change="inputPageSet.onFileChange($event,0)" 
                                    type="file">
                                    <a
                                    :href="inputPageSet.giftData.tutor_pc"
                                    target="blank"
                                    > {{  inputPageSet.giftData.tutor_pc_name }}</a>
                                </td>
                            </tr>
                            <tr>
                                <th width="180"><span class="color-1">*</span>강사 페이지 (모바일)</th>
                                <td class="float-center">
                                    <input 
                                    @change="inputPageSet.onFileChange($event,1)" 
                                    type="file">
                                    <a
                                    :href="inputPageSet.giftData.tutor_mobile"
                                    target="blank"
                                    > {{  inputPageSet.giftData.tutor_mobile_name }}</a>
                                </td>
                            </tr>
                            <tr>
                                <th width="180"><span class="color-1">*</span>담당자 페이지 (PC)</th>
                                <td class="float-center">
                                    <input
                                    @change="inputPageSet.onFileChange($event,2)" 
                                    type="file">
                                    <a
                                    :href="inputPageSet.giftData.client_pc"
                                    target="blank"
                                    > {{  inputPageSet.giftData.client_pc_name }}</a>
                                </td>
                            </tr>
                            <tr>
                                <th width="180"><span class="color-1">*</span>담당자 페이지 (모바일)</th>
                                <td class="float-center">
                                    <input 
                                    @change="inputPageSet.onFileChange($event,3)"
                                    type="file">
                                    <a
                                    :href="inputPageSet.giftData.client_mobile"
                                    target="blank"
                                    > {{  inputPageSet.giftData.client_mobile_name }}</a>
                                </td>
                            </tr>
                            <tr>
                                <th width="180"><span class="color-1">*</span>완료 페이지 (PC)</th>
                                <td class="float-center">
                                    <input
                                    @change="inputPageSet.onFileChange($event,4)"
                                    type="file">
                                    <a
                                    :href="inputPageSet.giftData.complete_pc"
                                    target="blank"
                                    > {{  inputPageSet.giftData.complete_pc_name }}</a>
                                </td>
                            </tr>
                            <tr>
                                <th width="180"><span class="color-1">*</span>완료 페이지 (모바일)</th>
                                <td class="float-center">
                                    <input 
                                    @change="inputPageSet.onFileChange($event,5)"
                                    type="file">
                                    <a
                                    :href="inputPageSet.giftData.complete_mobile"
                                    target="blank"                                    
                                    > {{  inputPageSet.giftData.complete_mobile_name }}</a>
                                </td>
                            </tr>                            
                        </tbody>
                    </table>         
                    <button 
                    @click="inputPageSet.settGiftData()"
                    class="btn-default float-right mt-5">저장</button>           
                </div>
            </div>
        </div>
    </div>
</template>
<script>



// import { useToast } from 'vue-toastification';
// import axios from '@/plugins/axios.js'

import { onMounted, reactive } from 'vue'
import Swal from 'sweetalert2';
import { useRouter } from 'vue-router'
import { useStore } from 'vuex';
import CarrotDatePicker from '@/components/common/CarrotDatePicker.vue'
import axios from '@/plugins/axios.js'
export default{
    layout:"myPIMS",
    components: {CarrotDatePicker},
    setup(){
        const store  = useStore();
        const router = new useRouter();
        const holiday = reactive({
            sdate: '',
            edate: '',            
            holidayCount : {
                tutor_count : 0,
                client_count : 0,
            },

            getHolidayData : async () => {
                    await axios.get(`/rest/resource/getHolidayGiftCnt`,{
                        params : {
                            start_date: holiday.sdate
                            ,end_date: holiday.edate
                        }
                    })
                    .then((res) => {
                        holiday.holidayCount = res.data.data;
                    });
            },
            downExcel : async (type) => {
                //stage 주소 
                if(location.origin.includes('local') || location.origin.includes('stage')){
                    if(holiday.sdate == '') {
                        location.href =  `https://stageapi.carrotians.net/excel/holiday_gift_list?type=${type}`;    
                    }
                    else {
                        location.href = `
                        https://stageapi.carrotians.net/excel/holiday_gift_list?type=${type}&start_date==${holiday.sdate}&end_date=${holiday.edate}`;
                    }
                }
                //운영 주소 
                else {
                    if(holiday.sdate == '') {
                        location.href =  `https://api.carrotians.net/excel/holiday_gift_list?type=${type}`;    
                    }
                    else {
                        location.href = `
                        https://api.carrotians.net/excel/holiday_gift_list?type=${type}&start_date==${holiday.sdate}&end_date=${holiday.edate}`;
                    }
                }
            },
            deleteHolidayData : () => {
                if(confirm(`현재 저장된 주소 데이터가 모두 삭제됩니다.\n삭제하시겠습니까?`)){
                    axios.delete(`/rest/resource/truncateHolidayGift`).then(() => {
                        alert('주소 데이터가 삭제되었습니다.');
                        holiday.getHolidayData();
                    })
                }
            },

        });
        const inputPageSet = reactive({
            giftData : {
                // 명절 선물 페이지에서 노출될 이미지 
                tutor_mobile : '',
                tutor_pc : '',
                client_mobile :'',
                client_pc :'',
                complete_mobile :'',
                complete_pc :'',
                //배송지 입력 기간
                start_date :'',
                end_date :'',
                //파일네임 
                tutor_pc_name: '',
                tutor_mobile_name: '',
                client_pc_name: '',
                client_mobile_name: '',
                complete_pc_name: '',
                complete_mobile_name: '',
            },
            fileArray : ['','','','','',''],
            goPage : (taget) => {
                if(location.origin.includes('local') || location.origin.includes('stage')){
                    window.open(`https://stage-biz.carrotglobal.com/holiday_gift/${taget}`, "_blank")    
                }
                else {
                    window.open(`https://carrotglobal.com/holiday_gift/${taget}`, "_blank")    
                }
            },
            
            getGiftData: async () =>{
                let res = await axios.get(`/rest/resource/getHolidayGiftManagement`);
                Object.assign(inputPageSet.giftData, res.data.data);

            },
            settGiftData: async() =>{
                let formData = new FormData();
                formData.append("start_date", inputPageSet.giftData.start_date);
                formData.append("end_date", inputPageSet.giftData.end_date);
                formData.append("tutor_pc", inputPageSet.fileArray[0]);
                formData.append("tutor_mobile", inputPageSet.fileArray[1]);
                formData.append("client_pc", inputPageSet.fileArray[2]);
                formData.append("client_mobile", inputPageSet.fileArray[3]);
                formData.append("complete_pc", inputPageSet.fileArray[4]);
                formData.append("complete_mobile", inputPageSet.fileArray[5]);
                
                axios.post(`/rest/resource/updateHolidayGiftManagement`,formData)
                .then(() => {
                    alert(`저장되었습니다.`)
                    inputPageSet.getGiftData();
                })
            },
            onFileChange : async (e,idx) => {
                let files = e.target.files || e.dataTransfer.files;
                if (!files.length)
                    return;
                    inputPageSet.fileArray[idx] = files[0];
            },
        })
        onMounted(() => {
            let auth = false;
            if (store.state.isManageSupportAdmin || store.state.isPlanningTeam) //명절 선물 페이지는 총무 및 기획팀 
              auth = true;

            if( auth==false ) {
                router.go(-1);
                Swal.fire({
                    title : '명절 선물 관리',
                    text  : '접근 권한이 없습니다.'
                });
                return;
            }
            holiday.getHolidayData();
            inputPageSet.getGiftData();
        })
        return{ holiday,inputPageSet }
    }
}
</script>
<style lang="scss" scoped>
.list{
    justify-content: flex-end;
}

.custom-btn{
    display: inline-block;
}
</style>